<template>
  <div :class="{container:true,notScroll:isScroll}">
       <!-- 顶部 -->
    <MoTitle :showLeft="false" title="智慧人事服务大厅" @moTitltFn="navPanel"></MoTitle>
    <div class="smartDTContainer">
      <!-- 头部 -->
      <div class="moTitle center">
          <h1 style="color:#fff;font-size: 1.5rem;;margin-bottom:20px">智慧人事服务大厅</h1>
          <button class="showBtn" @click="$router.push('/moShow')">立即预约演示</button>
      </div>

      <div class="contentBgColor center">
        <div class="ptDescContent">
          <img src="@/assets/product/smartPT/dtBj.png" alt="" style="width:100%;border-radius: 10px 10px 0 0 ;">
          <div class="ptDescContentText">
           智慧人事服务大厅可将不同角色用户的待办事项、消息提醒、应用服务等智能化的展示在平台首页，基于统一身份认证，为不同角色提供一站式人事服务，方便用户进行一站式人事业务办理和服务。
          </div>
        </div>
        <h2 class="h2Desc">智慧人事服务大厅的价值优势</h2>
        <span class="spanDesc"></span>
        <div class="moValueContent">
          <div v-for="(item,index) in valueList" :key="index" class="moValueItem">
            <img :src="item.src" alt="" class="moVlaueImg">
            <div class="moValueText">{{item.content}}</div>
          </div>
        </div>
      </div>

      <div class="contentBgColor center">
        <h2 class="h2Desc">主要功能</h2>
        <span class="spanDesc"></span>
        <img src="@/assets/product/zhrsSys.png" alt="" style="width:100%;margin:30px 0 40px">
      </div>

    </div>
     <MoBottom></MoBottom>
    <MoFoot></MoFoot>
  </div>
</template>

<script>
import MoTitle from '@/components/Motitle'
// import MoListDesc from '@/components/moListDesc'
export default {
  name: 'moSmartDT',
  components: {
    MoTitle
    // MoListDesc
  },
  data () {
    return {
      isScroll: false,
      valueList: [
        { content: '按用户角色重构服务，形成个性化的用户工作桌面。', src: require('@/assets/solute/SmartCampus/smartOne.png'), hoverScr: require('@/assets/oneHover.png') },
        { content: '智能消息推送，面向教职工提供丰富、智能的预警服务。', src: require('@/assets/solute/SmartCampus/smartTwo.png'), hoverScr: require('@/assets/towHover.png') },
        { content: '人事应用服务智能分组，方便快速筛选功能模块。', src: require('@/assets/solute/SmartCampus/smartThree.png'), hoverScr: require('@/assets/threeHover.png') }
      ]
    }
  },
  methods: {
    navPanel (v) {
      this.isScroll = v
    },
    getHeight () {
      if (window.innerWidth > 800) {
        this.$router.push('/product/productSmartDT')
      }
    }
  },
  created () {
    // 监听可视窗口宽度
    window.addEventListener('resize', this.getHeight)
    this.getHeight()
  },
  destroyed () {
    window.removeEventListener('resize', this.getHeight)
  }
}
</script>

<style scoped>
* {

  margin: 0;
  padding: 0;
  font-size: 16px;
  color: #222;

}
img {
   object-fit: cover;
}
.container{
  width: 100%;
}
.notScroll{
  height: 0rem;
  overflow: hidden;
}
.moreBtn{
  width: 6rem;
  height: 2rem;
  border-radius: 1.875rem;
  border: 1px solid rgba(211, 218, 228, 0.5);
  line-height: 2rem;
  color: #444444;
  font-size: .875rem;
  background-color: #fff;
}
.h2Desc{
  font-size: 1.375rem;
  color: #222;
  margin-top: 2.5rem;
}
.spanDesc{
  display: inline-block;
  background-color: #D70C0C;
  width: 1.875rem;
  height: .25rem;
  border-radius: .1875rem;
  margin-top: 1.25rem;
}
.center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.showBtn{
  width: 8.75rem;
  height: 2.5rem;
  background: linear-gradient(135deg, #FF8484 0%, #C61F1F 100%);
  border-radius: 2.125rem;
  border: 0;
  line-height: 2.5rem;
  font-size: .875rem;
  color: #fff;
}
.contentBgColor{
  width: 100%;
    padding: 0 1.25rem;
box-sizing: border-box;
  background: linear-gradient(180deg, #FFFFFF 0%, #F7F5F4 100%);
}

.moTitle{
  position: relative;
  width: 100%;
  height: 15rem;
  background: url("~@/assets/product/smartPT/banner.png") center no-repeat;
  padding: 0 2.5rem;
  background-size: cover;
  object-fit: cover;

  color: #fff;
  text-align: center;
    display: flex;
  flex-direction: column;
  justify-content: center;
}
.smartDTContainer{
  padding-top: 2.75rem;
}

.ptDescContent{
  width: 100%;
  background: #FFFFFF;
  box-shadow: 0px 8px 24px 0px rgba(0,0,0,0.15);
  border-radius: 10px;
  margin-top: 20px;
}
.ptDescContentText{
  font-size: 16px;
  font-weight: 400;
  color: #222222;
  line-height: 26px;
  text-shadow: 0px 8px 24px rgba(0,0,0,0.15);
  padding: 20px;
  box-sizing: border-box;
}

.moValueContent{
  width: 100%;
  padding: 20px 0;
  box-sizing: border-box;
}
.moValueItem{
  display: flex;
  align-items: center;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  background: #FFFFFF;
  border-radius: 8px;
  margin-bottom: 20px;
  border: 1px solid rgba(220,223,230,.5);
  box-sizing: border-box;
}
.moVlaueImg{
  width: 40px;
  height: 40px;
  object-fit: cover;
}
.moValueText{
  font-size: 16px;
  font-weight: 400;
  color: #222222;
  line-height: 24px;
  margin-left: 20px;
}
</style>
